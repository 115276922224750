import React from "react"
//import leftArrow from "../../static/images/leftArrow.svg"
function BackButton(props) {
  return (
    <button
      className="btn btn-outline-primary  text-primary"
      onClick={() => window.history.back()}
    >
      {props.children ? props.children : "Back"}
    </button>
    // <div
    //   title="Back"
    //   style={{ cursor: "pointer" }}
    //   className="float-left pb-4 "
    //   onClick={() => window.history.back()}
    // >
    //   <img src={leftArrow} alt="Back" className="mr-2" style={{ width: 22 }} />
    //   <div className="d-inline-block mt-1">
    //     {" "}
    //     {props.children ? props.children : "Back"}
    //   </div>
    // </div>
  )
}
export default BackButton
