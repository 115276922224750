import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import BackButton from "../components/BackButton"

const NotFoundPage = () => (
  <Layout hideTimeline={true}>
    <SEO
      title="404: Page Not found"
      description="Page not found. Vulcan AI helping Enterprises do less with more."
    />
    <div class="container">
      <div class="row" style={{ height: "100vh" }}>
        <div class="col-md-12">
          <div class="error-template text-center" style={{ paddingTop: 200 }}>
            <h1 className="pb-5">Oops!</h1>
            <h2 className="pb-2">404: Page Not Found</h2>

            <div class="error-actions" style={{ paddingTop: 50 }}>
              <BackButton>Go Back</BackButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
